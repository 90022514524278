import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/packages/villa-clementine/src/gatsby/gatsby-tpl/mdx-layout-default.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "75%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden",
        "marginBottom": "1.0725rem"
      }
    }}>{` `}<iframe parentName="div" {...{
        "style": {
          "border": "0",
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        },
        "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.6730477245787!2d6.938290915495757!3d43.5925260791235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cc2817ad12b799%3A0x7c021157c7979c69!2s%20Chemin%20du%20Nid%20du%20Loup%2C%2006550%20La%20Roquette-sur-Siagne%2C%20France!5e0!3m2!1sfr!2sus!4v1578418262669!5m2!1sfr!2sus",
        "frameBorder": "0",
        "allowFullScreen": true
      }}></iframe>{` `}</div>
    <ul>
      <li parentName="ul">{`Autoroute A8, sortie 42 - Cannes La Bocca`}</li>
      <li parentName="ul">{`Avion : aéroport de Nice-Côte-d’Azur à 20km`}</li>
      <li parentName="ul">{`Train : gare TGV de Cannes (ligne Paris-Nice)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      